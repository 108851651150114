import React from "react"
import Page from "../../components/page"
import ResponsiveImageGrid from "../../components/responsive-image-grid"
import { themes } from "../../styles/themes"
import paths from "../../utils/paths"
import useScreenSize from "../../utils/useScreenSize"

const streetsOfZagrebGrid = (labels, isSmallScreen) => [
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: isSmallScreen ? 50 : 100,
        mode: isSmallScreen ? undefined : "full-screen",
        src:
          "/images/streets-of-zagreb/x-armchair-red-croatian-association-of-artists.jpg",
        text: !isSmallScreen && labels.streetsOfZagrebRendesvousText,
        textContainerClass: "streets-of-zagreb-rendesvous-text-container",
        textClass: "streets-of-zagreb-rendesvous-text"
      },
      {
        type: "html",
        weight: isSmallScreen ? 50 : 0,
        html: isSmallScreen && (
          <div className="paragraph-div-centered-relatively streets-of-zagreb-rendesvous-text-container">
            <p className="streets-of-zagreb-rendesvous-text">
              {labels.streetsOfZagrebRendesvousText}
            </p>
          </div>
        )
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: isSmallScreen
      ? [
          {
            type: "image",
            weight: 49.25,
            src: "/images/streets-of-zagreb/croatian-association-of-artists.jpg"
          },
          {
            type: "image",
            weight: 49.25,
            src: "/images/streets-of-zagreb/x-armchair-red-fountains.jpg",
            text: labels.streetsOfZagrebXArmchairRedText,
            textContainerClass:
              "streets-of-zagreb-x-armchair-red-text-container",
            textClass: "streets-of-zagreb-x-armchair-red-text"
          }
        ]
      : [
          {
            type: "image",
            weight: 49.25,
            src: "/images/streets-of-zagreb/x-armchair-red-fountains.jpg",
            text: labels.streetsOfZagrebXArmchairRedText,
            textContainerClass:
              "streets-of-zagreb-x-armchair-red-text-container",
            textClass: "streets-of-zagreb-x-armchair-red-text"
          },
          {
            type: "image",
            weight: 49.25,
            src: "/images/streets-of-zagreb/croatian-association-of-artists.jpg"
          }
        ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: isSmallScreen
      ? [
          {
            type: "html",
            weight: 100,
            html: (
              <div className="paragraph-div-centered-relatively streets-of-zagreb-kulusic-text-container">
                <p className="streets-of-zagreb-kulusic-text">
                  {labels.streetsOfZagrebKulusicText}
                </p>
              </div>
            )
          }
        ]
      : [
          {
            type: "image",
            weight: 32.33,
            src: "/images/streets-of-zagreb/im-the-wild-one-chair-kulisic.jpg"
          },
          {
            type: "image",
            weight: 32.33,
            src:
              "/images/streets-of-zagreb/im-the-wild-one-chair-kulisic-parking.jpg"
          },
          {
            type: "image",
            weight: 32.33,
            src:
              "/images/streets-of-zagreb/im-the-wild-one-chair-kulisic-leaning.jpg"
          }
        ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    unified: isSmallScreen,
    grid: isSmallScreen
      ? [
          {
            type: "image",
            weight: 49.25,
            src: "/images/streets-of-zagreb/im-the-wild-one-chair-kulisic.jpg"
          },
          {
            type: "image",
            weight: 49.25,
            src:
              "/images/streets-of-zagreb/im-the-wild-one-chair-kulisic-parking.jpg"
          }
        ]
      : [
          {
            type: "html",
            weight: 100,
            html: (
              <div className="streets-of-zagreb-kulusic-text-container">
                <p className="streets-of-zagreb-kulusic-text">
                  {labels.streetsOfZagrebKulusicText}
                </p>
              </div>
            )
          }
        ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: isSmallScreen
      ? [
          {
            type: "image",
            weight: 100,
            src:
              "/images/streets-of-zagreb/im-the-wild-one-chair-kulisic-leaning.jpg",
            text: labels.streetsOfZagrebImTheWildOneText,
            textContainerClass:
              "streets-of-zagreb-im-the-wild-one-text-container",
            textClass: "streets-of-zagreb-im-the-wild-one-text"
          }
        ]
      : []
  }
]

export default function StreetsOfZagreb({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      theme={themes(labels).ipsBlack(false)}
      title={labels.streetsOfZagreb}
      description={labels.streetsOfZagrebDescription}
      keywords={labels.streetsOfZagrebKeywords}
      ogUrl={paths(labels).streetsOfZagreb}
      ogImage="/images/og-images/streets-of-zagreb.jpg"
      headerPadding={isSmallScreen}
      footerPadding
    >
      <ResponsiveImageGrid
        grid={streetsOfZagrebGrid(labels, isSmallScreen)}
        gridClass="streets-of-zagreb-grid-class"
      />
    </Page>
  )
}
